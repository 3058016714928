import React from "react";

import {
    TextField,
    RichTextField,
    // EditButton,
    // ShowButton,
} from "react-admin";

import { Card, CardContent, CardActionArea, CardHeader, CardMedia } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {strapiImage} from '../../../api';
import { DateMomentField } from '../../Field/DateMomentField';

export const AlertShowCard = ({alert, specialLink='', additionnalDescription=null}) => {
    let history = useHistory();
    // const { user } = useSelector(state => state.account)

    const redirectClick = () => {
            history.push("/alerts/"+alert.id+"/show"+specialLink);
    }

    if (!alert?.id) {
        return (null)
    }

    const img = strapiImage(alert?.images[0], '<', 'alert.cover');
    // const imgPadding = img?.height && img?.width?(img.height * 100 / img.width):36;
    const imgPadding = 80;

        return (
            <Card key={alert.id} elevation={8} style={{maxWidth: 300,}}>
                <CardActionArea onClick={redirectClick}>
                    <CardMedia
                        style={{height:0, paddingTop:imgPadding+"%", width: 300}}
                        image={img.url}
                        title={alert.title}
                    />
                    <CardContent style={{minHeight:'100px'}}>
                        { additionnalDescription  && <>{additionnalDescription } <br /> <br />  </>}
                        <ShowMoreRichTextField initOpen={true}>
                            <RichTextField record={alert} source="description" label="Description" style={{ width: '10px' }}/>
                        </ShowMoreRichTextField>
                        { (alert?.report_info && parseFloat(alert?.report_info['total'])) && <><br /><br />
                            Total ramassé : {alert?.report_info && alert?.report_info['total'] } Kg
                            {/* <ShowDetailQuantity item={event} /> */}
                        </>}
                        
                        <TextField record={alert} label="Département" source="county" /><br />
                        <TextField record={alert} label="Département" source="town" /><br />
                        <TextField record={alert} label="Département" source="postcode" /><br />
                    </CardContent>
                    <CardHeader
                        subheader={<>
                            Date : <DateMomentField record={alert} label="Date" source="created_at" />
                        </>}
                    />
                </CardActionArea>
            </Card>
        )
}

const ShowMoreRichTextField = ({children, initOpen = false}) => {
    const open = initOpen;

    const MIN_HEIGHT = 40;
    const BORDER_HEIGHT = 40;
    return (
        <div style={{maxHeight: !open?MIN_HEIGHT+50:999, minHeight: MIN_HEIGHT, overflow:"hidden", position: "relative", maxWidth:"100%"}} >
            {children}
            <div style={{position:open?"relative":"absolute", top: open?0:MIN_HEIGHT+10,  height: BORDER_HEIGHT, width: "100%", background: open?"none":"linear-gradient(#FFFFFF00, #FFFFFFFF)", textAlign:"center"}}>
                {/* <Button variant="contained" color="secondary" onClick={()=>{setOpen(!open)}}>Afficher la suite</Button> */}
            </div>
        </div>
    )
}
