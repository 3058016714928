import { useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { Collapse, Typography, Box, Button } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export const TextLongField = (props) => {
    const { source, minHeight = 100, expandable=true, forceOpen=false } = props;

    const [open, setOpen] = useState(false);
    const elementRef = useRef(null);

    const record = useRecordContext(props);
    const value = get(record, source);
    const [textHeight, setTextHeight] = useState(0);

    if (forceOpen && open === false) setOpen(true);  
    
    useEffect(()=>{
        setTextHeight(elementRef?.current?.clientHeight);
    }, [])

    // useEffect(()=>{
    //     console.log(textHeight, minHeight);
    // }, [textHeight])

    if (!value) return null;
    const Content = value.split(/\n/).map((item, index) => (<Typography  variant="caption" key={index}>{item}<br /></Typography>));

    return (<span>
        { (props?.label !== "" && forceOpen === true) &&<Typography variant="caption" color={'textSecondary'} >{props?.label || source} :</Typography> }
        <Box m={1}>
            <Collapse in={open} collapsedSize={minHeight} onClick={()=>setOpen((textHeight > minHeight)?!open:false)} style={{ padding: 0, position: 'relative' }}>
                {(textHeight > minHeight && expandable) && (
                    <div style={{    
                        position: 'absolute',
                        top: '0px',
                        right: '10px',
                        cursor: 'pointer'
                    }}>
                        {open?(<>
                            <Button
                                variant="outlined"
                                color="secondary"
                                size='small'
                                startIcon={<ArrowUpwardIcon />}
                            >Afficher moins</Button>
                            </>
                        ):(<></>
                        )}
                    </div>
                )}
                <div 
                    ref={elementRef} 
                    style={{
                        paddingBottom: (open&&expandable)?40:20, 
                        paddingTop: (open&&expandable)?40:0, 
                        paddingLeft: (open&&expandable)?10:0, 
                        paddingRight: (open&&expandable)?10:0
                    }}>
                    {Content}
                </div>
                {(textHeight > minHeight && expandable) && (
                    <div style={{    
                        position: 'absolute',
                        bottom: '0px',
                        right: 'calc(50% - 80px)',
                        cursor: 'pointer'
                    }}>
                        {open?(
                            <Button
                                variant="outlined"
                                color="secondary"
                                size='small'
                                startIcon={<ArrowUpwardIcon />}
                            >Afficher moins</Button>
                        ):(
                            <Button
                                variant="contained"
                                color="secondary"
                                size='small'
                                startIcon={<ArrowDownwardIcon />}
                            >Afficher plus</Button>
                        )}
                    </div>
                )}
            </Collapse>
        </Box>
    </span>);
}