import React from "react";

import {
    Datagrid,
    List,
    TextField,
    DateField,
    ShowButton,
    useListContext,
    BooleanInput,
    ListContextProvider,
    useListController,
    DeleteButton,
    Pagination
} from "react-admin";
import { useSelector } from 'react-redux';   
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { ContentHeaderTuto } from "../../UI/TutoVideo";
import { AlertField } from "../../Field/AlertField";
import { UserField } from "../../Field/UserField";
import { TextLongField } from "../../Field/TextLongField";
import { FilesField } from "../../Field/FilesField";
import { AlertShowCard } from "../Alert/AlertShowCard";

const AlertInternPagination = props => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />

export const AlertInternList = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
                <Card>
                    <CardHeader title={<ContentHeaderTuto title="Notes Interne" type="0" />} />
                    <CardContent>                        
                        <List
                            filters={[
                            // <TextInput label="Rechercher" source="q" alwaysOn/>, 
                            <BooleanInput label="Afficher par alerte" source="alert" alwaysOn />
                            ]}
                            actions={null}
                            pagination={<AlertInternPagination />}
                            filterDefaultValues={{alert:true}}
                            perPage={100}
                            bulkActionButtons={false}
                            {...props} >
                            <AlertInternListByAlert/>
                            {/* } */}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export const AlertInternDeleteButton = props => {
    const { record } = props;
    const user = useSelector(state => state.account.user);
    
        if (record?.user?.id === user?.id) {
            return <DeleteButton {...props}/>
        } else {
            return null
        }
}

const AlertInternDataGrid = ({ children, ...props }) => (
    <ListContextProvider value={useListController(props)}>
        <Datagrid>
            <TextField source="id" />
            <UserField label="Créateur" source="user"></UserField>
            <TextLongField source="description" expandable={false} label="Description"/>
            <AlertField source="alert" showButton={false} />
            <FilesField source="documents" mode="nb"/>
            <DateField source="published_at" label={"Crée le "}/>
            <ShowButton label="Voir" />
            <AlertInternDeleteButton label="Supprimer"/>
        </Datagrid>
    </ListContextProvider>
)

const AlertInternListByAlert = (props) => {
    const { ids, data, filterValues } = useListContext();

    if (filterValues.alert !== true)
        return (<AlertInternDataGrid {...props} />);

    if (data[ids[0]] && data[ids[0]].alertIntern?.length > 0) {
        return (
            <div style={{ margin: '1em' , flex:1, flexDirection:'row', display:'flex', flexWrap:'wrap'}}>
                {ids.map(id =>  {
                    const alert = data[id].alertIntern[0].alert;

                    return <Card key={id} style={{ margin: '0.1em' }}>
                        <CardHeader title={'Alerte #'+alert.id}></CardHeader>
                        <CardContent>
                            <AlertShowCard 
                                alert={alert} 
                                specialLink={'/5'} 
                                //"# notes interne: " + data[id]?.alertIntern?.length
                                additionnalDescription={"Nombre de notes internes: " + data[id]?.alertIntern?.length+"\n"}
                            />
                            </CardContent>                    
                    </Card>

                }
                )}
            </div>
        )
    } else {
        return(<>No  Data</>)
    }
}
